import React, { useEffect } from 'react'

interface Props {
    show: boolean;
    onClose: () => void;
    children: React.ReactChild | React.ReactChild[];
}

const Modal = ({ show, onClose, children }: Props) => {
    useEffect(() => {
        const overflow = show ? "hidden" : "auto"
        document.body.style.overflow = overflow
    }, [show])

    function onClickOverlay(e: React.SyntheticEvent<HTMLButtonElement>) {
        // only close when actually clicking on the overlay instead of the modal
        const target = e.target as HTMLElement
        if (target.classList.contains('modal-overlay')) {
            onClose()
        }
    }

    return (
        <div style={{display: show ? "block" : "none"}}>
            <div className="modal-overlay" onClick={onClickOverlay}>
                <button className="close" onClick={onClose}></button>
                <div className="modal">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal
